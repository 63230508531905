import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToolbox, faEnvelope, faLocationDot, faCirclePhone } from '@fortawesome/pro-light-svg-icons';
import logo from './media/logoWhite.png';
import './App.css';

function App() {
	return (
		<div id="container">
			<div id="app">
				<img id="logo" src={logo} alt="Q Stay Logo" />
				<h1><FontAwesomeIcon icon={faToolbox} /> Maintenance</h1>
				<p>Sorry about this, but our booking site is currently undergoing important maintenance. Please check back soon!</p>
				<p>You can still make bookings over the phone, so give us a call and one of our lovely staff will help find you the perfect accommodation.</p>
				<div id="contact">
					<h2>Contact Us</h2>
					<p><span><FontAwesomeIcon icon={faEnvelope} /></span> <span>bookings@qstay.com.au</span></p>
					<p><span><FontAwesomeIcon icon={faLocationDot} /></span> <span>Suite 54 / Level 2, Retail Precinct (Circle on Cavill),<br />9 Ferny Avenue,<br />Surfers Paradise, Queensland,<br />Australia, 4217</span></p>
					<p><span><FontAwesomeIcon icon={faCirclePhone} /></span> <span>+61 7 5635 8941</span></p>
				</div>
			</div>
		</div>
	);
}

export default App;
